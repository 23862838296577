import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <hr className="footer-divider" />
      <div className="container py-4">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="footer-links">
              <NavLink to="/privacypolicy">Privacy Policy</NavLink>
              <NavLink to="/terms">Terms of Service</NavLink>
              <NavLink to="/intellectualpropertypolicy">Intellectual Property Policy</NavLink>
              <NavLink to="/lawenforcementguidelines">Law Enforcement Guidelines</NavLink>
              <NavLink to="/virtualitempolicy">Virtual Items Policy</NavLink>
              <NavLink to="/childsafety">Children's Safety Policy</NavLink>
              <NavLink to="/deleteaccount">Delete Your Account</NavLink>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center footer-copyright">
            <b>Powered by AIBC Foundation Inc</b>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;