import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const AdJunction = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Shuffle Hub – Social Networks, Global Reach',
      content: 'Connect with millions of potential customers. Our platform gives your brand the visibility it needs in diverse, high-value markets, making it easier than ever to engage with audiences ready to embrace your products and services.',
      image: '/images/adjunction/pic2.jpg'
    },
    {
      id: 2,
      subtitle: 'Krizaar – E-commerce, Seamless Integrations',
      content: "List your products and services on Krizaar for maximum visibility.  Run ad campaigns actively targeting shoppers engaged to make real time purchases.",
      image: '/images/adjunction/pic3.jpg'
    },
    {
      id: 3,
      subtitle: 'Affluence – Viral, Organic, Scale',
      content: 'Launch your campaigns to the Affluence network, where Affiliates, Influencers and Content Creators can be accessed to help scale your brand by making it go viral, organically. ',
      image: '/images/adjunction/pic4.jpg'
    },
    {
      id: 4,
      title: 'Engage and Captivate Audiences Across the Globe',
      subtitle: 'Interactive Campaigns',
      content: 'Go beyond basic advertising—create campaigns that truly engage. Use interactive content to captivate your audience, boost engagement rates, and build lasting brand loyalty. Our proprietary social platforms task board enables you to command and direct an influencer network to target specific tasks that enable instant virality of brands on a mass scale..',
      image: '/images/adjunction/pic5.jpg'
    },
    {
      id: 5,
      title: 'Engage and Captivate Audiences Across the Globe',
      subtitle: 'Interactive Campaigns',
      content: `Go beyond basic advertising—create campaigns that truly engage. Use interactive content to captivate your audience, boost engagement rates, and build lasting brand loyalty. Our proprietary patent pending social platforms task board enables you to command and direct an influencer network to target specific tasks that enable instant virality of brands on a mass scale.`,
      image: '/images/adjunction/pic6.jpg'
    }
      
  ]

  const heroSectionItems = {
    title: 'Ready to take your brand global?',
    subtitle: 'Advertise with Ad Junction: Amplify Your Brand Globally',
    content: 'Unlock a World of Opportunities – Realize the power of Social Commerce.',
    content2: "At Ad Junction, we're not just a marketplace—we are a dynamic bridge to access a new form of e-commerce, Social Commerce.",
    image: '/images/adjunction/pic1.jpg'
  }

  const middleBannerContent = {
    title: "Blockchain-Powered Advertising",
    subtitle: "Advertise with Confidence—Transparency and Control at Your Fingertips",
    steps: [
      {
        title: `Real-Time Campaign Management`,
        description: `Monitor and control your campaigns with complete transparency. Our blockchainpowered platform gives you real-time insights into ad placement, budget allocation, and performance, so you can optimize your strategy on the fly.`,
      },
      {
        title: `Cost-Effective Advertising`,
        description: `Say goodbye to hidden fees and unexpected costs. With smart contracts, you set the terms and only pay for what you get—ensuring a straightforward, budget-friendly advertising experience.`,
      },
      {
        title: `Secure, Fast Payments`,
        description: `Streamline your financial processes with fast, secure payments powered by blockchain technology. Focus on growing your brand while we handle the rest with transparent and efficient payment remittances.`,
      }
    ],
  };
  
  return (
    <>
      <CommonOtherLinkComponent title="Why Advertise with Ad Junction?" subtitle="One Platform, Three Networks" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={middleBannerContent} />
    </>
  )
}

export default AdJunction
