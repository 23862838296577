import React, { useEffect } from "react";

const PrivacyPolicy = () => {
    return (
        <>
            <section className="text-white">
                <div className="container">
                    <div className='terms-of-service my-5'>
                        <h1 className="my-3">Privacy Policy for Shuffle</h1>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div style={{ textAlign: 'justify' }}>This Privacy Policy applies to all Shuffle services, including apps, websites, software, and related services, across any platform or device. Shuffle Inc. ("Shuffle," "we," or "us") controls these services and is committed to protecting your privacy. This policy explains how we collect, use, and share personal information of users aged 13 and above. For users under 13, please refer to our Children’s Privacy Policy.</div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Information We Collect</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>We collect various types of information from you:
                                            <ol>
                                                <li><b>Information You Provide:</b> This includes account details (name, age, email, phone number), user-generated content (photos, videos, messages), and payment information when making purchases.</li>
                                                <li><b>Information from Other Sources:</b> If you log in with third-party services like Facebook or Google, we collect profile info. We also receive data from advertisers and partners about your activities on other platforms.</li>
                                                <li><b>Automatically Collected Information:</b> This includes data like your IP address, device information, location, browsing history, and Cookies.</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>How We Use Your Information</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>We use your information to:
                                            <ul>
                                                <li>Provide and improve Shuffle's services.</li>
                                                <li>Customize your content and ads.</li>
                                                <li>Communicate with you about updates or promotions.</li>
                                                <li>Enhance the social features of the platform (e.g., connecting with friends).</li>
                                                <li>Detect fraud and illegal activity</li>
                                                <li>Ensure the security of the platform.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Sharing Your Information</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Shuffle does not sell your personal information, but we may share it for business purposes:
                                            <ul>
                                                <li><b>With Service Providers:</b> We share data with partners who help us with functions like payments, marketing, and analytics.</li>
                                                <li><b>Within Our Corporate Group:</b> We may share information with affiliated entities.</li>
                                                <li><b>During Business Transfers:</b> In the event of a merger or sale, your data may be transferred.</li>
                                                <li><b>For Legal Reasons:</b> We may disclose data to comply with legal obligations.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Your Rights</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>You can:
                                            <ul>
                                                <li>Request access to, correct, or delete your information through your account settings.</li>
                                                <li>Opt out of targeted advertising.</li>
                                                <li>Control Cookies through your browser settings.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Data Security and Retention</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>We take measures to protect your data from unauthorized access. We retain your data as long as necessary to provide services or meet legal requirements.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Children’s Privacy</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>For users under 13, refer to our Children’s Privacy Policy. If we discover that we have collected data from a child under 13, we will delete the information.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Privacy Policy Updates</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>We may update this policy and will notify you of significant changes. Please check this page regularly for updates.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Contact Us</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>For questions or requests regarding this Privacy Policy, visit: 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PrivacyPolicy;
