import React, { useEffect } from "react";

const IntellectualPropertyPolicy = () => {
    return (
        <>
            <section className="text-white">
                <div className="container">
                    <div className='terms-of-service my-5'>
                        <h1 className="my-3">Intellectual Property Policy</h1>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div style={{ textAlign: 'justify' }}>Shuffle respects the intellectual property rights of others and expects users to do the same. According to Shuffle’s Terms of Service and Community Guidelines, posting, sharing, or sending content that infringes on someone else's copyrights, trademarks, or intellectual property is prohibited.</div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Copyright</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Copyright protects original works like music, videos, and written content. It covers the specific expression of an idea, but not the idea itself. Content infringing on someone else’s copyright without proper authorization or a legal basis may violate Shuffle’s policies. However, exceptions like "fair use" (in the U.S.) or "fair dealing" (in the EU) allow limited uses of copyrighted material without permission in certain situations.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Copyright Infringement and Content Removal</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Content found to violate copyrights can be removed, and accounts may be suspended or terminated for repeat violations. Users involved in such violations may be barred from creating new accounts on Shuffle.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Rightsholders’ Information: Reporting Copyright Infringement</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Rightsholders can contact users directly to resolve disputes or file a Copyright Infringement Report with Shuffle. The report should include all necessary details, and incomplete reports may not be investigated. Rightsholders should be aware that intentionally submitting a false claim may lead to legal penalties.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Users’ Rights: Counter-Notification</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Users who believe a copyright claim against their content is incorrect may contact the copyright holder or file a Counter-Notification with Shuffle. This process takes time and may involve legal actions if unresolved.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Trademark</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>A trademark protects symbols, words, or designs identifying the source of goods or services. Trademark infringement occurs when a trademark is used in a way that causes confusion about the origin or affiliation of goods or services. However, using trademarks for legitimate reasons like commentary, parody, or fan pages is generally allowed as long as it doesn’t mislead others.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Trademark Infringement and Content Removal</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Content that violates trademark rights can be removed, and repeated violations may lead to account suspension or termination.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Reporting Trademark Infringement</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Trademark owners can file a Trademark Infringement Report with Shuffle. Accurate and complete information must be provided for Shuffle to investigate claims. Misleading reports can result in legal consequences.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Counter-Notification for Trademark Issues</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>If a user receives a trademark infringement notice and believes it’s incorrect, they can contact the trademark owner or file a Counter-Notification.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>General Guidance</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Shuffle users are responsible for the content they post. If you are unsure about the legal implications of your content regarding copyright or trademark, consider seeking legal advice before submitting or reporting content on Shuffle.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default IntellectualPropertyPolicy;
