import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const Voting = () => {
  const contentArray = [
    {
      id: 1,
      content: "Shuffle's voting system is a blockchain-based framework that empowers users to govern the platform similar to a Decentralized Autonomous Organization (DAO)",
      image: '/images/pay/pic2.jpg'
    },
    {
      id: 2,
      content: "The community collectively influences the platform's algorithm through decentralized voting",
      image: '/images/pay/pic4.jpg'
    },
    {
      id: 3,
      content: "This enables users to propose changes, rank content, and shape the platform's evolution.",
      image: '/images/pay/pic3.jpg'
    },
    {
      id: 4,
      content: 'Every vote is securely recorded on the blockchain, ensuring transparency and accountability.',
      image: '/images/pay/pic5.jpg'
    },
  ]

  const heroSectionItems = {
    title: 'Shuffle Voting System',
    subtitle: ' Community-Governed Social Media',
    image: '/images/pay/pic1.jpg'
  }


  return (
    <>
      <CommonOtherLinkComponent title="Why Choose Shuffle Voting System?" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={undefined} />
    </>
  )
}

export default Voting
