import React from 'react';
import { IoDocuments, IoPeople } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

const Card = ({ bgimage, image, title, url, description }) => {
    return (
        <NavLink to={url} className='text-white text-decoration-none explore-card col-lg-5 col-'>
            <img className=' bg-img img-fluid' src={bgimage} alt={title} />
            <div className='explore-card-image'>
                <img className='img-fluid' src={image} alt={title} />
            </div>
            <div className="explore-card-content">
                <h3 className="explore-card-title m-0">{title}</h3>
                <p className="explore-card-description m-0">{description}</p>
            </div>
        </NavLink>
    );
};

export default Card;
