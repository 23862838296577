import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const SellersClub = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Global Reach, Local Impact',
      content: `The Sellers Club is an exclusive platform that seamlessly integrates your access to Shuffle Hub’s social media platform with your Krizaar’s global e-commerce footprint.`,
      content2: 'Break boundaries and sell your products across the globe, connecting with buyers in North America, Europe, Asia and beyond. Expand your business to new horizons and make your mark in the world’s largest markets.',
      image: '/images/sellersclub/pic2.jpg'
    },
    {
      id: 2,
      subtitle: 'Double Your Selling Power',
      content: 'Maximize your exposure by selling on two powerful platforms, Shuffle Hub and Krizaar. With the Seller’s Club, your products automatically get listed on both our cutting-edge e-commerce platform and our dynamic social media platform, Shuffle Hub.',
      image: '/images/sellersclub/pic3.jpg'
    },
    {
      id: 3,
      subtitle: 'Shopify',
      content: 'Connect your Shopify store for seamless integration and order processing. Accessing a global audience couldn’t get easier.',
      content2: 'Make your products seamlessly available for dropship or send samples to influencers for mass reach.',
      content3: 'Upon registration, Sellers will be able to also list their products for Dropship or send samples to Influencers with over 5,000 followers for mass reach.',
      image: '/images/sellersclub/pic4.jpg'
    },
    {
      id: 4,
      subtitle: 'DROPSHIP',
      content: 'The Sellers Club platform also facilitates Dropship as a service. Launch your store from anywhere in the world with convenience.t currency at your convenience (additional terms apply).',
      content2: 'Dropship thousands of products to global audiences without the hassles of logistics management.',
      content3: 'E-commerce should not be a stressful experience. Leave the management to us while you focus on scaling your store and brand.',
      image: '/images/sellersclub/pic5.jpg'
    },
  ]

  const heroSectionItems = {
    title: 'Welcome to the Sellers Club',
    subtitle: 'Your Global Marketplace',
    content2: "Join the Seller’s Club, where cutting-edge technology meets seamless global commerce. Whether you're an established brand, a new seller or someone looking to dropship, The Seller’s Club empowers you to reach consumers across global markets. Tap into new markets and connect with a world of buyers eager for unique products.",
    image: '/images/sellersclub/pic1.jpg',
  }

  const middleBannerContent = {
    title: "Seamless Payouts",
    subtitle: "Get Paid with Ease and Confidence",
    description: `
      We believe in prompt, transparent payments. Here’s how our payout process works:
    `,
    steps: [
      {
        title: `1. Sales
        `,
        description: `List products on Krizaar to earn revenue from Shuffle Hub.`,
      },
      {
        title: "2. Transit",
        description: `Once sold, the products will be in transit and tracked.`,
      },
      {
        title: "3. Delivery",
        description: `Upon delivery, customer satisfaction is confirmed.`,
      },
      {
        title: "4. Release ",
        description: `Successful delivery releases funds immediately.`,
      },
    ],
  };

  return (
    <>
      <CommonOtherLinkComponent title="Why Choose The Seller's Club?" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={middleBannerContent} loginPage='https://krizaar.com/vendorlogin' />
    </>
  )
}

export default SellersClub
