import React, { useEffect } from "react";

const LawEnforcementGuidelines = () => {
    return (
        <>
            <section className="text-white">
                <div className="container">
                    <div className='terms-of-service my-5'>
                        <h1 className="my-3">Shuffle Law Enforcement Guidelines</h1>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div style={{ textAlign: 'justify' }}>
                                <p>These guidelines outline the process for law enforcement officials seeking user data from Shuffle, a platform provided by various Shuffle entities based on the user’s location. Shuffle is dedicated to cooperating with law enforcement while safeguarding the privacy and rights of its users.</p>
                                <p>Shuffle has procedures guided by legal and human rights standards to ensure compliance with legitimate law enforcement requests, while minimizing negative human rights impacts. Requests that violate Shuffle’s policies are rejected.</p>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>1. Basic Requirements</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Law enforcement requests must be directed to the appropriate Shuffle entity, based on the user's location:
                                            <ul>
                                                <li><b>U.S. users:</b> Shuffle Inc., Culver City, CA</li>
                                                <li><b>U.K. users:</b> Shuffle Information Technologies UK Limited, London, UK</li>
                                                <li><b>EU and EEA users:</b> Shuffle Technology Limited, Dublin, Ireland</li>
                                                <li><b>Other regions:</b> Shuffle Pte. Limited, Singapore</li>
                                            </ul>
                                            <p>Requests should be submitted via the official Shuffle webform, and documents must be in PDF format and English. Shuffle does not respond to informal requests or those not made by law enforcement.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>2. Types of User Data</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Shuffle may hold the following types of user data:
                                            <ul>
                                                <li><b>Subscriber Information:</b> Username, email, phone number, account creation date, device information, and IP address.</li>
                                                <li><b>Login Data:</b> IP addresses for logins and logouts.</li>
                                                <li><b>Interaction Data (non-content):</b> IP logs, video creation dates, etc.</li>
                                                <li><b>Content Data:</b> Videos, comments, direct messages.</li>
                                            </ul>
                                            <p>Each request must specify the type of data needed and the legal basis for requesting it. Content data typically requires a judicial order or equivalent.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>3. Making a Request</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Each request must include:
                                            <ul>
                                                <li>Law enforcement authority details, including the name, location, and contact information of the requesting officer or court.</li>
                                                <li>Relevant user account information, such as username or other approved identifiers, along with a screenshot if possible.</li>
                                                <li>The scope of data requested and a clear date range.</li>
                                                <li>The legal basis for the request, specifying the legal process and the context of the investigation.</li>
                                                <li>Indication of whether user notification is permitted.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>4. User Notifications</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Shuffle’s policy is to notify users before disclosing their data, unless doing so would violate laws, compromise investigations, or endanger individuals.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>5. Preservation Requests</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Shuffle will preserve user data for 90 days upon a valid request submitted via the webform. Preservation requests must specify the user data and date range and must be made on law enforcement letterhead. An additional 90-day extension may be granted, but multiple extensions are generally not honored.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>6. Emergency Requests</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Shuffle can process emergency requests when there is an imminent threat of harm or risk of death or injury. These requests must come from a sworn law enforcement official and can be submitted via Shuffle’s Emergency Disclosure Request Form.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>7. Mutual Legal Assistance Treaty (MLAT) Requests</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                        <p>Shuffle may require a law enforcement agency to submit a request through the MLAT framework if the agency is in a different country than the relevant Shuffle entity. This ensures compliance with cross-border legal processes.</p>
                                        <p>Law enforcement officials must ensure all submitted information is accurate and necessary. Shuffle reserves the right to seek reimbursement for handling law enforcement requests.</p>
                                        <p>Shuffle updates these guidelines as needed based on legal or operational changes, with updates posted on the Shuffle website.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Contact Us</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>For questions or requests regarding this Privacy Policy, visit:
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LawEnforcementGuidelines;
