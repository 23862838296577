import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { 
//   auth, 
//   googleProvider, 
//   signInWithPopup, 
//   signInWithEmailAndPassword 
// } from './firebase';

const LoginComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleEmailLogin = async (e) => {
    // e.preventDefault();
    // try {
    //   await signInWithEmailAndPassword(auth, email, password);
    //   navigate('/dashboard'); // Redirect to dashboard or homepage
    // } catch (err) {
    //   setError(err.message);
    // }
  };

  const handleGoogleLogin = async () => {
    // try {
    //   await signInWithPopup(auth, googleProvider);
    //   navigate('/dashboard'); // Redirect to dashboard or homepage
    // } catch (err) {
    //   setError(err.message);
    // }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password'); // Navigate to Forgot Password page
  };

  return (
    <div className="container mt-5">
    <div className="row justify-content-md-center">
      <div className="theme-background-color-one shadow login-form">
        <div className="inner-login">
        <h2 className="text-center mb-4">Login</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleEmailLogin}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="btn theme-btn-one w-100 mb-3">
            Login
          </button>
        </form>

        <button
          className="btn btn-outline-danger w-100 mb-3"
          onClick={handleGoogleLogin}
        >
          Login with Google
        </button>

        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-link"
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </button>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => navigate('/register')}
          >
            Register
          </button>
        </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default LoginComponent;
