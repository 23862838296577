import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const KrizaarAffluence = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Built for Influencers and Affiliates',
      content: 'Whether you’re an influencer or an affiliate, Affluence provides the tools you need to succeed, allowing you to monetize four revenue streams on one platform.',
      image: '/images/affluence/pic2.jpg'
    },
    {
      id: 2,
      title: 'Affiliates',
      subtitle: 'Promote Brands',
      content: "Choose the products that resonate with you, generate custom links, and share them across your social networks. Must have 1000 followers to qualify for this.",
      image: '/images/affluence/pic3.jpg'
    },
    {
      id: 3,
      title: 'Influencers',
      subtitle: 'Channel Monetization',
      content: 'Monetize your Shuffle Hub channel by tracking content analytics and ad revenues. Must have 1000 followers, 100,000 views and a minimum 3 posts to qualify for this.',
      image: '/images/affluence/pic4.jpg'
    },
    {
      id: 4,
      title: 'Creators',
      subtitle: 'Live Monetization',
      content: 'Monetize your Shuffle Hub Live streaming by tracking content analytics and community gifts and earnings. Must have 1000 followers, 100,000 views and a minimum 3 posts to qualify for this.',
      image: '/images/affluence/pic5.jpg'
    },
    {
      id: 5,
      title: 'Taskboard',
      subtitle: 'Social Media Tasks',
      content: 'A revolutionary new way for affiliates and influencers to engage with brands. Our daily task board offers opportunities to promote brands globally, driving engagement across social platforms. Must have 5000 followers to qualify for this.',
      image: '/images/affluence/pic2.jpg'
    },
  ]

  const heroSectionItems = {
    title: 'Welcome to Affluence',
    title2: "Shuffle Hub’s Creator Fund",
    subtitle: 'Redefining Affiliate and Influencer Marketing',
    content2: "Affluence is the world’s first affiliate and influencer marketing platform, powered by blockchain technology. Designed to promote brands on a global scale, Affluence is where influencers and affiliates come together to create, share, and earn—securely and seamlessly.",
    image: '/images/affluence/pic1.jpg'
  }

  const bottomBigContent = {
    title: 'AFFLUENCE',
    subtitle: 'Where Influencers Get Rewarded!',
    para1: 'If you’re an influencer with over 5,000 followers, Affluence offers even more. You can request product samples from participating suppliers directly through the platform. Build authentic, engaging content with real product experiences, while earning at the same time.',
    para2: 'If you’re an influencer with over 100,000 followers, Affluence invites you to join our premier club where you can enjoy access to sponsored events, exclusive perks and meetups that allow you to stand out to your community.',
    para3: 'One Platform, 4 Revenue Streams, Global Reach',
    bottomLine: 'Join Affluence today and monetize like never before!'
  }

  const middleBannerContent = {
    title: "Social Workforce",
    subtitle: "Join the New Wave of Marketing",
    description: 'The Social Workforce is a revolutionary new way for affiliates and influencers to engage with brands. Our daily task board offers opportunities to promote brands globally, driving engagement across social platforms',
    steps: [
      {
        title: `Targeted Social Tasks`,
        description: `Perform tactical tasks designed to increase engagement, visibility, and brand loyalty.`,
      },
      {
        title: `Boost Your Earnings`,
        description: `Each task completed brings you closer to earning more, making the social workforce a highly productive space for influencers, affiliates, and brands alike.`,
      }
    ],
  };

  return (
    <>
      <CommonOtherLinkComponent title="Why Choose Affluence?" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={middleBannerContent} bottomBigContent={bottomBigContent} loginPage='https://krizaar.com/affiliate_login' />
    </>
  )
}

export default KrizaarAffluence
