import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaGlobe } from "react-icons/fa";

const ContactUs = () => {
    return (
        <div className="container">

            <div className="contact-form-container">
                <div className="contact-form">
                    <h2>Get in touch</h2>
                    <form>
                        <input type="text" placeholder="Name" />
                        <input type="email" placeholder="Email" />
                        <input type="text" placeholder="Subject" />
                        <textarea placeholder="Message"></textarea>
                        <button type="submit">Send Message</button>
                    </form>
                </div>
                <div className="contact-info">
                    <h2>Contact us</h2>
                    <div className="contact-info-parts">
                        <div className="icon">
                            <FaMapMarkerAlt />
                        </div>
                        <p className="mb-0"><strong>Address:</strong><br /> 700 S Rosemary Ave, Ste 204<br /> West Palm Beach, Fl 33401</p>
                    </div>
                    {/* <div className="contact-info-parts">
                        <div className="icon">
                            <FaPhoneAlt />
                        </div>
                        <strong>Phone:</strong> +1235 2355 98
                    </div> */}
                    <div className="contact-info-parts d-flex align-items-start">
                        <div className="icon">
                            <FaEnvelope />
                        </div>
                        <div>
                            <p><strong>Emails:</strong></p>
                            <div className=" d-flex flex-column">
                                <p><b>Investors:</b> <a className="text-white" href="mailto:investors@shflhub.com">investors@shflhub.com</a></p>
                                <p><b>Media:</b> <a className="text-white" href="mailto:media@shflhub.com">media@shflhub.com</a></p>
                                <p><b>Creators:</b> <a className="text-white" href="mailto:creators@shflhub.com">creators@shflhub.com</a></p>
                                <p><b>Affluence:</b> <a className="text-white" href="mailto:affluence@shflhub.com">affluence@shflhub.com</a></p>
                                <p><b>Sellers:</b> <a className="text-white" href="mailto:sellers@shflhub.com">sellers@shflhub.com</a></p>
                                <p><b>Advertise:</b> <a className="text-white" href="mailto:advertise@shflhub.com">advertise@shflhub.com</a></p>
                                <p><b>Support:</b> <a className="text-white" href="mailto:support@shflhub.com">support@shflhub.com</a></p>
                                <p><b>Developers:</b> <a className="text-white" href="mailto:developers@shflhub.com">developers@shflhub.com</a></p>
                                <p><b>Marketing:</b> <a className="text-white" href="mailto:marketing@shflhub.com ">marketing@shflhub.com </a></p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="contact-info-parts">
                        <div className="icon">
                            <FaGlobe />
                        </div>
                        <strong>Website:</strong> <a className="text-white text-decoration-none" href="https://shuffle.studio/">Shuffle</a>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
