import React from "react";
import { NavLink } from "react-router-dom";
import Explore from "./Explore";
import Studios from "./components/Studios";

const Home = () => {
  return (
    <div className="home-page">
      <div className="mobile-box">
        <div className="mobile-image">
          <div className="left-text">
            <img src="/left-text.svg" alt="Mobile App" className="img-fluid" />
          </div>
          <img src="/mobile.svg" alt="Mobile App" className="img-fluid mobile-main-image" />
          <div className="right-text">
            <img src="/right-text.svg" alt="Mobile App" className="img-fluid" />
          </div>
          <div className="right-text2">
            <img src="/right-text2.svg" alt="Mobile App" className="img-fluid" />
          </div>
          <div className="get-your-app-main">
            <p className=" text-white">Get the Shuffle Hub app:</p>
            <div className="get-your-app mb-3">
              <NavLink to='https://apps.apple.com/us/app/shuffle-by-krizaar/id6736882167'>
                <img src="/app-store.png" className="img-fluid" alt="App Store" />
              </NavLink>
              <NavLink to='https://play.google.com/store/apps/details?id=vr.shuffle.hub'>
                <img src="/play-store-button.webp" className="img-fluid" alt="App Store" />
              </NavLink>
            </div>
            <div className="get-your-app">
              <NavLink className='primary-button' to='https://t.me/shufflehubbetatesting'>
                Telegram Beta Group
              </NavLink>
              <NavLink className='primary-button' to='https://discord.gg/wAwMYnZs'>
                Discord Beta Server
              </NavLink>
            </div>
          </div>
        </div>

        <div className="shuffle-video">
          <video autoPlay loop muted className="logo">
            <source src="/shuffle.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <Studios />
    </div>

  );
};

export default Home;
