import React, { useState, useEffect, useRef } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setIsSearchOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark custom-navbar">
      <div className="container-fluid">
        <a className="navbar-brand d-flex align-items-center" href="#">
          <img src="/shuffle.png" alt="Brand Logo" className="brand-logo" />
          {/* <h2>SHUFFLE</h2> */}
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto me-auto mb-2 mb-lg-0 gap-3 mx-3">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/danceoff">Dance-Off</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/microdramas">Microdramas</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/monetization">Monetization</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/hub">Hub</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contactus">Contact Us</NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink className="nav-link" to="/bytes">Bytes</NavLink>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" href="#">Wallet</a>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" href="#">Settings</a>
            </li> */}
            {/* <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Explore
              </a>
              <ul className="dropdown-menu bg-black">
                <li><NavLink className="dropdown-item text-decoration-none px-4" to="/sellersclub">Sellers</NavLink></li>
                <li><NavLink className="dropdown-item text-decoration-none px-4" to="/affluence">Affluence</NavLink></li>
                <li><NavLink className="dropdown-item text-decoration-none px-4" to="/shuffledrops">Dropship</NavLink></li>
                <li><NavLink className="dropdown-item text-decoration-none px-4" to="/adjunction">Advertise</NavLink></li>
                <li><NavLink className="dropdown-item text-decoration-none px-4" to="/compliance">Compliance</NavLink></li>
                <li><NavLink className="dropdown-item text-decoration-none px-4" to="/logistics">Logistic</NavLink></li>
                <li><NavLink className="dropdown-item text-decoration-none px-4" to="/studios">Studios</NavLink></li>
                <li><NavLink className="dropdown-item text-decoration-none px-4" to="/streaming">Streaming</NavLink></li>
                <li><NavLink className="dropdown-item text-decoration-none px-4" to="/voting">Voting</NavLink></li>
                <li><NavLink className="dropdown-item text-decoration-none px-4" to="/moderation">Moderation</NavLink></li>
                <li><NavLink className="dropdown-item text-decoration-none px-4" to="/monetization">Monetization</NavLink></li>
              </ul>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
