import React from 'react';
import ContentMiddleBanner from './ContentMiddleBanner';

const Content = ({ contentArray, title, subtitle, middleBannerContent, bottomBigContent }) => {
  const middleIndex = Math.ceil(contentArray.length / 2);

  return (
    <>
      <div className='why-choose-us'>
        {title && <h2 className='why-choose-us-heading' id='why-choose-us'>{title}</h2>}
        {subtitle && <h2 className='text-center text-white' id='why-choose-us'>{subtitle}</h2>}
        {
          contentArray.map((item, index) => {
            return (
              <div className='my-5 py-5' key={index}>
                {index === middleIndex && middleBannerContent && <ContentMiddleBanner middleBannerContent={middleBannerContent} />}

                <section className={index % 2 === 0 ? 'alignment-one container' : 'alignment-two container'}>
                  <div className="alignment-one-text">
                    <h5>{item.title}</h5>
                    <h2>{item.subtitle}</h2>
                    <p>
                      {item.content.split('\n').map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </p>
                    <p>{item.content2}</p>
                    <p>{item.content3}</p>
                  </div>
                  <div className="alignment-one-image">
                    <img src={item.image} alt="content-image" />
                  </div>
                </section>
              </div>
            )
          })
        }
        {bottomBigContent && <div className='section microdramas-banner2 my-5 py-5 text-center container text-white'>
          <h1 style={{ fontSize: '5rem', fontWeight: '800' }}>{bottomBigContent.title}</h1>
          <p style={{ fontSize: '1.5rem', fontWeight: '600' }}>{bottomBigContent.subtitle}</p>
          <p style={{ fontSize: '1.5rem', fontWeight: '600' }}>{bottomBigContent.para1}</p>
          <p style={{ fontSize: '1.5rem', fontWeight: '600' }}>{bottomBigContent.para2}</p>
          <p style={{ fontSize: '2rem', fontWeight: '600' }}>{bottomBigContent.para3}</p>
          <h1 style={{ fontSize: '5rem', fontWeight: '800' }}>{bottomBigContent.bottomLine}</h1>
        </div>}
      </div>
    </>
  );
};

export default Content;
