import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const Logistics = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Comprehensive Services',
      content: 'From freight forwarding to motor carrier services, we cover every aspect of logistics.',
      image: '/images/logistics/pic2.jpg'
    },
    {
      id: 2,
      subtitle: 'In-House Expertise',
      content: "With Digiloads and our motor carrier division, you get personalized service and seamless logistics solutions.",
      image: '/images/logistics/pic3.jpg'
    },
    {
      id: 3,
      subtitle: 'Seamless Integration',
      content: 'As part of the Krizaar ecosystem, our logistics services are fully integrated with your e-commerce operations, making business management smoother and more efficient.',
      image: '/images/logistics/pic4.jpg'
    },
    {
      id: 4,
      title: 'Motor Carrier Services',
      subtitle: 'Powered by Precision and Reliability',
      content: `Krizaar Logistics also operates a robust motor carrier division, with a fleet of 18 semitrucks ready to transport your goods safely and efficiently.
      • Dedicated Hauling: We transport your products on time, every time, with our fleet of trucks ready to meet your regional and long-haul needs.
      • Expert Drivers: Our professional drivers are trained to handle all types of freight, ensuring your products are moved with care and precision.`,
      image: '/images/logistics/pic5.jpg'
    },
    {
      id: 5,
      subtitle: 'End-to-End Logistical Support for Your Entire Supply Chain',
      content: `We go beyond transportation at Krizaar Logistics, offering complete logistical support to power your business operations.
      • Warehousing and Distribution: Secure, efficient storage solutions keep your supply chain running smoothly.
      • Inventory Management: Our advanced systems help you track your products from warehouse to destination, so you always know where your inventory stands.`,
      image: '/images/logistics/pic6.jpg'
    }
      
  ]

  const heroSectionItems = {
    title: 'Welcome to Krizaar Logistics',
    subtitle: 'Your Trusted Partner in Global Fulfillment',
    content2: "At Krizaar Logistics, we understand that the key to a successful business lies in efficient and reliable logistics. That’s why we offer a full range of logistics solutions tailored to the needs of our sellers and suppliers. From freight forwarding to motor carrier services, we ensure your products reach their destination on time and in perfect condition.",
    image: '/images/logistics/pic1.jpg'
  }

  const middleBannerContent = {
    title: "Introducing Digiloads",
    subtitle: "Simplifying Your Shipping Process",
    description: 'Digiloads, the dedicated freight forwarding division of Krizaar Logistics, takes the complexity out of shipping. Whether your freight is moving across borders or within the same country, our experienced team ensures a smooth journey from origin to destination.',
    steps: [
      {
        title: `Drayage Freight Hauling`,
        description: `Moving goods from ports to warehouses or distribution centers has never been easier. Our drayage services keep your supply chain seamless and cost-effective.`,
      },
      {
        title: `In-House Customs Brokerage`,
        description: `Clearing customs can be daunting, but with Digiloads, your shipments glide through smoothly. We handle all the paperwork, ensuring there are no delays or hidden costs.`,
      }
    ],
  };
  
  return (
    <>
      <CommonOtherLinkComponent title="Why Choose Krizaar Logistics?" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={middleBannerContent} />
    </>
  )
}

export default Logistics
