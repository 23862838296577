import React, { useEffect } from "react";

const VirtualItemsPolicy = () => {
    return (
        <>
            <section className="text-white">
                <div className="container">
                    <div className='terms-of-service my-5'>
                        <h1 className="my-3">Virtual Items Policy</h1>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div style={{ textAlign: 'justify' }}>The Shuffle Live Stream Program (referred to as the "Program") is provided by Shuffle, allowing users to engage in live streaming and related features. This policy applies to users who reside outside the European Economic Area, United Kingdom, and Switzerland. To participate in the Program, users must be 18 years or older, or have the permission of a parent or legal guardian if they are between 18 and the age of majority in their region.</div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Information We Collect</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Eligibility for the Program
                                            <ul>
                                                <li>Users under the legal age of majority can join with a parent or guardian's permission.</li>
                                                <li>Select users over 18 are chosen to participate based on criteria like content quality and follower count.</li>
                                                <li>Only users 18 or older can buy Coins, send or receive Gifts, or earn Diamonds and withdraw them.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3>Coins</h3>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Who can purchase Coins?</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>Users 18 years or older (or of legal age in their region) can purchase virtual Coins using approved payment methods.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Coin Purchases</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>The price of Coins will be shown at the time of purchase, and users are responsible for any payment-related fees.</li>
                                                <li>Once purchased, Coins are credited to the user’s account.</li>
                                                <li>Purchases are non-refundable, and EU users acknowledge their right to cancel is waived once Coins are delivered.</li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Using Coins</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>Coins are for buying virtual Gifts within the platform and cannot be exchanged for cash or transferred to others.</li>
                                                <li>They expire if the account is terminated and are not transferable by law (e.g., in the event of death).</li>
                                                <li>Shuffle reserves the right to manage and regulate Coins, including removing them, with notice to the user.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3>Gifts</h3>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Who can purchase Gifts?</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>Users 18 years or older can buy virtual Gifts by exchanging Coins..</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Purchasing Gifts</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>Gifts are a limited license for using digital features, with the exchange rate between Coins and Gifts displayed at checkout.</li>
                                                <li>Sales of Gifts are final, and Gifts cannot be converted back into Coins or refunded.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Using Gifts</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>Gifts can be used to show appreciation for other users' content during live streams.</li>
                                                <li>Giving a Gift is public, and the recipient will see the user’s name and Gift details.</li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3>Diamonds</h3>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Who can earn Diamonds?</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>Content creators 18 years or older can earn Diamonds based on the Gifts they receive.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Earning Diamonds</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>Diamonds reflect the popularity of a user’s content and cannot be bought.</li>
                                                <li>They are not transferable or redeemable for Coins or Gifts and cannot be transferred without Shuffle’s consent.</li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Withdrawing Diamonds</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>Content creators can withdraw Diamonds in exchange for money, with payment made to a verified PayPal account or other authorized payment channel.</li>
                                                <li>Users must ensure accurate PayPal information to receive payment, and Shuffle reserves the right to verify users' identities before processing withdrawals.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3>Tax Responsibility</h3>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>Users are responsible for any taxes on withdrawals and may need to provide documentation for tax reporting.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3>Account Termination</h3>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>If a user’s account is terminated, all Coins, Gifts, or Diamonds will be canceled without refund. Users are advised to use any virtual items before account termination.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3>Contact Information</h3>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>For issues related to Coins, Gifts, or Diamonds, users can contact Shuffle at <a href="">support link</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Withdrawing Diamonds</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>Content creators can withdraw Diamonds in exchange for money, with payment made to a verified PayPal account or other authorized payment channel.</li>
                                                <li>Users must ensure accurate PayPal information to receive payment, and Shuffle reserves the right to verify users' identities before processing withdrawals.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Data Security and Retention</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>We take measures to protect your data from unauthorized access. We retain your data as long as necessary to provide services or meet legal requirements.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Children’s Privacy</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>For users under 13, refer to our Children’s Privacy Policy. If we discover that we have collected data from a child under 13, we will delete the information.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Privacy Policy Updates</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>We may update this policy and will notify you of significant changes. Please check this page regularly for updates.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Contact Us</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>For questions or requests regarding this Privacy Policy, visit:
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default VirtualItemsPolicy;
