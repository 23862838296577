import React from 'react'
import Explore from './Explore'

const Hub = () => {
  return (
    <>
      <Explore/>
    </>
  )
}

export default Hub
