import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const Streaming = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Blockchain-Based Transparency',
      content: 'Stream music with confidence, knowing that streaming data and royalty payments are securely tracked.',
      image: '/images/pay/pic2.jpg'
    },
    {
      id: 2,
      subtitle: 'NFT-Embedded Analytics',
      content: 'NFTs include real-time streaming analytics, enabling artists to see detailed insights into their audience engagement and earnings.',
      image: '/images/pay/pic4.jpg'
    },
    {
      id: 3,
      subtitle: 'Fair Monetization',
      content: 'Directly support artists through a transparent revenue-sharing model that rewards content creators for every stream.',
      image: '/images/pay/pic3.jpg'
    },
    {
      id: 4,
      content: 'Shuffle Streaming is reshaping the music industry by providing a fairer, more transparent way for artists to monetize their music and for fans to connect with their favorite tracks.',
      image: '/images/pay/pic5.jpg'
    },
  ]

  const heroSectionItems = {
    title: 'Shuffle Streaming',
    subtitle: 'The Blockchain-Powered Music Streaming Platform',
    content2: 'Shuffle Streaming is a revolutionary music streaming service built on blockchain technology, offering artists and listeners a transparent and fair way to experience music. Built on the blockchain, Shuffle Streaming tracks streaming data and monetization in real-time, ensuring that artists are fairly compensated and listeners enjoy exclusive content.',
    image: '/images/pay/pic1.jpg'
  }

  return (
    <>
      <CommonOtherLinkComponent title="Key Features of Streaming" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={undefined} />
    </>
  )
}

export default Streaming
