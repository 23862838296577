import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const Studios = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Why blockchain?',
      content: 'Blockchain technology enables the platform to shed transparency from social networks and monetization to e-commerce transactions and freight mobilization.',
      image: '/studios/img8.jpg'
    },
    {
      id: 2,
      title: 'We believe a transparent world is an accountable one, which leads to a fair and just world for all.',
      content: `Blockchain also allows us to decentralize user data.\n
      What this means is we do not collect or sell user data to or for third parties.`,
      image: '/studios/img7.jpg'
    },
    {
      id: 3,
      subtitle: 'It’s your data, your privacy, your control.',
      content: 'Content creators and artists can copyright, monetize and track their content analytics with the use of NFT minting tools.',
      image: '/studios/img5.jpg'
    },
    {
      id: 4,
      subtitle: 'Our platform never competes with our e-commerce vendors. ',
      content: `E-commerce vendors can authenticate supply chains and authorized distributorships using NFT minting tools in order to combat growing global counterfeit concerns.\n
      Supply Chain operators can mobilize freight sourced directly from ecommerce vendors allowing all parties to earn more and save more.`,
      image: '/studios/img4.jpg'
    },
    {
      id: 5,
      title: "Trust, transparency and integrity is our backbone. ",
      subtitle: 'One Platform, Global Commerce, Savings for all.',
      content: `Musicians can release their music directly on the Shuffle Studio’s platform and earn more with transparent web 3 analytics.\n
      It’s your creativity, nobody else should have rights over it.`,
      image: '/studios/img2.jpg'
    }
  ]

  const heroSectionItems = {
    title: 'Shuffle Studios',
    subtitle: 'Welcome to the world’s first everything app!!',
    content2: 'Shuffle Studios is a cutting-edge marketplace and platform for content creators powered by blockchain technology, offering artists, content creators, musicians, shop vendors, dropshippers and advertisers to connect with their audience globally.',
    image: '/studios/img6.jpg'
  }

  return (
    <>
      <CommonOtherLinkComponent title="" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={undefined} />
    </>
  )
}

export default Studios
