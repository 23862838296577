import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const KrizaDrops = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Premium Brands for US Consumers',
      content: 'Access a curated selection of premium brands that resonate with US consumers. With Kriza Drops, you’re offering top-tier products that meet the demands of a highvalue market, ensuring satisfied customers and repeat business.',
      image: '/images/krizadrops/pic2.jpg'
    },
    {
      id: 2,
      subtitle: 'Attractive Margins',
      content: 'Boost your earnings by tapping into the US market’s higher purchasing power. With Kriza Drops, you enjoy attractive margins, making it easier to grow your business and increase profitability.',
      image: '/images/krizadrops/pic3.jpg'
    },
    {
      id: 3,
      subtitle: 'Hassle-Free Fulfillment',
      content: 'Leverage Krizaar Logistics for seamless delivery of your products to US consumers. Our mandatory logistics service guarantees that your products arrive on time and in perfect condition, giving you peace of mind and your customers a great experience.',
      image: '/images/krizadrops/pic4.jpg'
    },
    {
      id: 4,
      title: 'Licensing Fees for Branded Products',
      subtitle: 'Sell Premium Brands with Confidence',
      content: 'Your subscription fees cover the licensing costs, allowing you to sell premium branded products that appeal to US consumers. This ensures your business remains competitive and profitable by offering high-quality, in-demand items.',
      image: '/images/krizadrops/pic5.jpg'
    },
    {
      id: 5,
      title: 'Expand Your Reach with Advertising Opportunities',
      subtitle: 'Maximize Visibility, Drive Sales',
      content: `Amplify your business by advertising on Krizaar and Shuffle, our e-commerce and social media platforms. Reach a global audience, engage with potential customers, and watch your sales soar. With Kriza Drops, your products won’t just reach the US—they’ll make a lasting impact.`,
      image: '/images/krizadrops/pic6.jpg'
    }
      
  ]

  const heroSectionItems = {
    title: 'Are you ready to expand your business beyond borders?',
    subtitle: 'Step Into the Future of Global Commerce with Kriza Drops',
    content: 'Your Gateway to the US Market—Effortlessly Dropship from India to the United States',
    content2: "With Kriza Drops, you can effortlessly bring your products from India to eager consumers in the United States. Unlock new opportunities, tap into a market with high purchasing power, and watch your business grow. Let Kriza Drops guide you through this innovative selling model.",
    image: '/images/krizadrops/pic1.jpg'
  }

  const middleBannerContent = {
    title: "Subscription Packages Tailored to Your Needs",
    subtitle: "Choose the Perfect Plan to Elevate Your Business",
    description: `
      Kriza Drops offers three distinct subscription packages, each designed to fit different seller needs:
    `,
    steps: [
      {
        title: `Silver Package:
        $35 per month`,
        description: `Perfect for new sellers testing the waters. Gain access to our dropshipping platform with a minimum inventory purchase of $500.`,
      },
      {
        title: `Gold Package:
        $360 per month`,
        description: `Ideal for sellers looking to scale. Enjoy inventory financing (subject to approval) and access to premium branded products. No minimum inventory purchase requirement.`,
      },
      {
        title: `Platinum Package:
        $1000 per month`,
        description: `Go all-in with everything in the Gold package plus agency establishment support. Onboard local sellers and suppliers, earning a percentage of sales from their revenue. Limited-Time Offer: Get the Platinum package for just $500!`,
      }
    ],
  };
  
  return (
    <>
      <CommonOtherLinkComponent title="Why Choose Kriza Drops?" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={middleBannerContent} />
    </>
  )
}

export default KrizaDrops
